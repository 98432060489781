(function(d, w){

  const nav = d.querySelector('.nav');
  const firstTier = nav.querySelectorAll('.nav__list > li > a');
  const menu = nav.querySelector('.nav__list');
  const allLinks = menu.querySelectorAll('a');
  const linksArray = Array.prototype.slice.call(allLinks);
  const navButton = d.getElementById('navButton');
  const login = d.getElementById('login');
  const forEach = [].forEach;
  const sublnks = menu.querySelectorAll('.nav__sub a');
  // const litog = d.getElementById('litog');
  // const tabIdxInputs = navSubLogin.querySelectorAll('input[aria-required="true"], button[type="submit"]');

  // console.log(subinputs);
  /*
  const rAF = w.requestAnimationFrame;
  const cAF = w.cancelAnimationFrame;
  */

  // menu.hidden = true;

  const getLoginForm = () => {
    const navSubLogin = d.querySelector('.nav__sub--login');
    if (navSubLogin) {
      return navSubLogin.querySelectorAll('input[aria-required="true"], button[type="submit"]');
    }
    return false;
  };

  const xlinks = Array.prototype.slice.call(d.getElementsByTagName('a')).filter(el => el.hostname && el.hostname !== window.location.hostname);

  forEach.call(xlinks, el => {
    // xlinks.forEach(el => {
    el.setAttribute('rel', 'noopener noreferrer');
    el.setAttribute('target', '_blank');
  });

  const wChg = mq => {

    if (mq.matches) {
      navButton.hidden = true;
      menu.hidden = false;
      // menu.setAttribute('aria-hidden', 'false');
    } else {
      navButton.hidden = false;
      menu.hidden = true;
      // menu.setAttribute('aria-hidden', 'true');
    }
  };

  if (matchMedia) {
    // const mq = w.matchMedia( '(min-width: 1136px)' );
    const mq = w.matchMedia( '(min-width: 1000px)' );
    mq.addListener(wChg);
    wChg(mq);
  }

  const xAllSubs = () => {
    const subs = menu.querySelectorAll('.nav__sub');
    setSubNavLinksTabIndex();
    forEach.call(subs, el => {
      // el.setAttribute('aria-hidden', 'true');
      el.hidden = true;
    });
    setSubNavLinksTabIndex();
  };

  /**
   * SETUP FUNCTION:
   * add a hassub class to allLinks that have a sub menu following them.
   * add an id to each sub menu
   * hide the sub menus
   * remove "hidden" after page load
   */
  // forEach.call(allLinks, (el) => {
  forEach.call(firstTier, (el) => {
    if (el.nextElementSibling) {
      const subId = 'sm' + linksArray.indexOf(el);
      el.setAttribute('aria-haspopup', true);
      el.setAttribute('aria-controls', subId);
      const sub = el.nextElementSibling;
      if (sub.nodeName === 'UL') {
        // sub.setAttribute('aria-hidden', true);
        // sub.hidden = false;
        sub.id = subId;
      }
    }
    if (String(w.location) === el.href) {
      el.setAttribute('aria-current', 'page');
    }
  });



  /**
   * sets tabindex to -1 on all subnav links.
   */
  const setSubNavLinksTabIndex = () => {
    // const sublnks = menu.querySelectorAll('.nav__sub a');
    forEach.call(sublnks, (el) => {
      el.setAttribute('tabindex', '-1');
    });
    // const subinputs = menu.querySelectorAll('.nav__sub--login input[aria-required="true"]');
    const tabIdxInputs = getLoginForm();
    if (tabIdxInputs) {
      forEach.call(tabIdxInputs, ip => {
        ip.setAttribute('tabindex', '-1');
      });
    }
  };

  /** SETUP:
   * run a function that will set the tabindex to -1 on all subnav links.
   */
  setSubNavLinksTabIndex();

  /**
   * add event listener to all top-level navigation links that will:
   * toggle all hidden attributes and
   * reset the tabindex for that subnav
   */
  forEach.call(firstTier, (el) => {

    if (el.getAttribute('aria-haspopup') === 'true') {
      const subnav = el.nextElementSibling;

      el.addEventListener('click', e => {
        e.preventDefault();
        /* const subnav = el.nextElementSibling; */
        // const visible = subnav.getAttribute('aria-hidden') === 'false';
        // const visible = subnav.getAttribute('hidden');
        const visible = subnav.hidden === false;

        if (visible) {
          // subnav.hidden = true;
          // subnav.setAttribute('aria-hidden', 'true');
          subnav.hidden = true;
          setSubNavLinksTabIndex();
        } else {
          xAllSubs();
          // subnav.setAttribute('aria-hidden', 'false');
          subnav.hidden = false;
          setSubNavFocus(subnav.id);
        }
        firstAndLast(el);
      }, false);

    }

  });

  /**
   * bind keyboard events for each subnav
   */
  const firstAndLast = el => {
    const links = Array.prototype.slice.call(d.getElementById(el.getAttribute('aria-controls')).querySelectorAll('a'));

    if (links) {
      const lnks = {
        'fst': links[0],
        'lst': links[links.length - 1]
      };

      lnks.fst.addEventListener('keydown', e => {

        if (e.keyCode === 9 && e.shiftKey) {
          e.preventDefault();
          el.focus();
        }
      });
      lnks.lst.addEventListener('keydown', e => {

        if (e.keyCode === 9 && !e.shiftKey) {
          e.preventDefault();
          el.focus();
        }
      });
    }
  };
  /**
   * hide all open subnavs.
   * reset tabindex to -1 on those subnav links then...
   * set an empty tabindex for all links in the subnav that has just been opened.
   * optionally do the same for the login form fields and button
   * set focus on that subnav.
   */
  const setSubNavFocus = (id) => {
    if (id) {
      const sn = d.getElementById(id);
      const subNavLinks = sn.querySelectorAll('a');
      forEach.call(subNavLinks, snl => {
        snl.setAttribute('tabindex', '');
      });

      if (sn.classList.contains('nav__sub--login')) {
        const tabIdxInputs = getLoginForm();
        forEach.call(tabIdxInputs, ipt => {
          ipt.setAttribute('tabindex', '');
        });
      }
      sn.focus();
    }
  };

  /**
   * get first and last navigation links.
   * used to send focus back to the toggle button after tabbing through the navigation list
   * return an object containing fn.first, fn.last
   */
  const getFirstAndLastNavLinks = () => {
    const focusable = menu.querySelectorAll('button, [href], input, [tabindex]:not([tabindex="-1"])');
    const firstFocusable = focusable[0];
    const lastFocusable = focusable[focusable.length - 1];
    // const topLinks = Array.prototype.slice.call(firstTier);
    // const lastLink = topLinks.length - 1;
    return {
      // 'first': topLinks[0],
      'first': firstFocusable,
      'last': lastFocusable
    };
  };

  d.addEventListener('click', e => {
    const insideNav = nav.contains(e.target);

    if (!insideNav) {
      xAllSubs();
    }
  });

  /**
   * SETUP function
   * adds an event listener to the navigation toggle button
   * this one is different from the one above due to the login form being within the nav.
   */
  navButton.addEventListener('click', () => {
    xAllSubs();
    const expanded = navButton.getAttribute('aria-expanded') === 'true';
    // menu.setAttribute('aria-hidden', String(expanded));
    menu.hidden = expanded;
    navButton.setAttribute('aria-expanded', String(!expanded));

    if (!expanded) {
      menu.focus();
      const elementRange = getFirstAndLastNavLinks();
      elementRange.last.addEventListener('keydown', (f) => {

        if (f.keyCode === 9 && !f.shiftKey) {
          f.preventDefault();
          navButton.focus();
        }
      });
      elementRange.first.addEventListener('keydown', (f) => {

        if (f.keyCode === 9 && f.shiftKey) {
          f.preventDefault();
          navButton.focus();
        }
      });
    }
  }, false);

  d.documentElement.className = d.documentElement.className.replace(/\bno-js\b/,'js');

  if ('IntersectionObserver' in w) {

    const up = d.querySelectorAll('.up__rel');

    if (up) {
      forEach.call(up, el => {
        el.classList.add('vh');
      });
    }

    const main = d.querySelector('#main');

    const options = {
      rootMargin: '800px 0% 0%',
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
    };

    const callback = (entries) => {
      forEach.call(entries, entry => {
        const targ = entry;
        if (targ.intersectionRect.top <= -400) {
          upbutton();
        } else {
          unbutton();
        }
      });
    };

    /*
     * remove all static "up" buttons
     */
    const unbutton = () => {
      const up = d.getElementById('up');
      if (up) {
        up.remove();
      }
    };

    /*
     * add the one up button
     */
    const upbutton = () => {
      const up = d.getElementById('up');
      if (!up) {
        const upp = d.createElement('p');
        upp.id = 'up';
        const upa = d.createElement('a');
        upp.classList.add('up');
        upa.classList.add('butt');
        upa.classList.add('butt__up');
        upa.href = '#top';
        upa.textContent = 'TOP';
        upp.appendChild(upa);
        main.appendChild(upp);
      }
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(main);

  }

  if (login) {
    const lbls = login.querySelectorAll('label');
    const forEach = [].forEach;

    forEach.call(lbls, el => {
      el.classList.add('in__el');
      const ipt = el.nextElementSibling;

      if (ipt.getAttribute('aria-required') === 'true') {
        el.classList.add('req');
      }

      ipt.addEventListener('focus', () => {
        el.classList.add('in__foc');
      });

      ipt.addEventListener('blur', () => {
        labelChoice(el, ipt);
      });

    });

    const labelChoice = (lbl, ipt) => {
      isEmpty(ipt) ? lbl.classList.remove('in__foc') : lbl.classList.add('in__foc');
    };

    const isEmpty = element => {
      return !element.value.replace(/^\s+/g, '').length;
    };

  }

})(document, window);
